import React, { useState } from "react"
import PropTypes from 'prop-types'
import { graphql, Link } from "gatsby"
import Img from 'gatsby-image'
// ---------------------------------------------
import Articles from "../components/articles"
import HeroGallery from "../components/heroGallery"
import Layout from "../components/layout"
import { NewsletterHomepage } from "../components/newsletter"
import Revealer from "../components/revealer"
import Splash, { ShowAfterSplash, useSplashState } from "../components/splash"
import SEO from "../components/seo"
// ---------------------------------------------

const IndexPage = ({ data }) => {

  // Content
  const { hero, featured, selected, disableJournal } = data.allContentfulHomepage.edges[0].node
  const articles = data.allContentfulArticle.edges

  // State
	const splashState = useSplashState()
	const { removeSplash, hideSplash } = splashState

  return (
    <Layout hasSplash removeSplash={removeSplash}>
      <SEO />

			<div>
				{!removeSplash && <Splash {...splashState} />}
			</div>

      {/* Hero Block */}
      {hero.isFullWidth ? (
        // 1-up
        <Revealer className="prel hero-fs" key="hero-full">
          <Link to={`/${hero.link.slug}`}>
            <Img className="pabs fs cover" fluid={hero.primaryImage.fluid} loading="eager" />
            <div className="pabs r0 hero-fs-title flex-center">
              <center>
                <ShowAfterSplash splashFadeOut={hideSplash}>
                  <h2 className={!hero.isTextBlack ? "color-white" : ""}>{hero.link.title}</h2>
                </ShowAfterSplash>
              </center>
            </div>
            <ShowAfterSplash
              className={`h4 pabs b0 max24 padx2 ${!hero.isTextBlack ? "color-white" : ""}`}
              splashFadeOut={hideSplash}
            >
              <p>
                <span>{hero.link.shortDescription}</span>
              </p>
              {/* <div className="outer has-links">
                <span className="link">Explore options</span>
              </div> */}
            </ShowAfterSplash>
          </Link>
        </Revealer>
      ) : (
        <HeroGallery data={hero} splashFadeOut={hideSplash} />
      )}

      {/* Features Block */}
      {featured && (
        <section className="no-bullets">
          <ul className="index pad">
            {featured.map((node, i) => (
              <ProductLink
                className={`inlb inner gutters`}
                {...node}
                key={`feature-link-${node.slug}-${i}`}
              />
            ))}
          </ul>
        </section>
      )}

      {/* Journal Block */}
      {articles && !disableJournal && (
        <Revealer key="homepage-journal-block">
          <section className="bg-cream innerx2 no-bullets">

            <div className="innerx4 align-center">
              <h1 className="upcase">The Journal</h1>
              <h1>Impressions of Making</h1>
            </div>

            <Articles articles={articles} />
            <div className="padx4 align-center">
              <Link to="/journal" className="btn">More in our Journal</Link>
            </div>
            <NewsletterHomepage />
          </section>
        </Revealer>
      )}

      {/* Selected Block */}
      {selected && (
        <section className="no-bullets">
          <ul className="index pad">
            {selected.map((node, i) => (
              <ProductLink
                className={`inlb inner gutters`}
                {...node}
                key={`selected-link-${node.slug}-${i}`}
              />
            ))}
          </ul>
        </section>
      )}

    </Layout>
  )
}

const ProductLink = (props) => {

  const image = props.thumbnail
  const isLandscape = image && image.fluid.aspectRatio > 1

  return (
    <li className={`${props.className} ${isLandscape ? 'w100' : 'span-6'}`}>
      <Revealer key={`hp-prod-${props.slug}`}>
        <Link className="block" to={`/${props.slug}`}>
          <figure>
            {isLandscape && image ? (
              <Img fluid={image.fluid} loading="eager" />
            ) : (
              <div className="portrait-ratio-image">
                <div className="pabs fs">
                  {image && (
                    <Img className="cover pabs fs" fluid={image.fluid} loading="eager" />
                  )}
                </div>
              </div>
            )}
            <figcaption className="outer max32">
              <p>
                <strong>{props.title}{" "}</strong>
                <span>{props.shortDescription}</span>
                {/* <br /> */}
                {/* <em>{props.thumbnailPrice || props.priceWithNote}</em> */}
              </p>
            </figcaption>
          </figure>
        </Link>
      </Revealer>
    </li>
  )
}

IndexPage.propTypes = {
  data: PropTypes.object.isRequired
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageQuery {
    allContentfulHomepage (
      limit: 1
    ) {
      edges {
        node {
          title
          description
          hero {
            ...HomepageHero
          }
          featured {
            ...Product
          }
          selected {
            ...Product
          }
          disableJournal
        }
      }
    }
    allContentfulArticle (
      limit: 3
      sort: {
        fields: [publishDate],
        order: DESC
      }
    ) {
      edges {
        node {
          ...Article
        }
      }
    }
  }
`

export const homepageLinkFragment = graphql`
  fragment HomepageHero on ContentfulHomepageHero {
    title
    primaryImage {
      # fluid(maxWidth: 1600, quality: 90) {
      #   src
      #   aspectRatio
      #   sizes
      #   srcSet
      # }
      fluid(maxWidth: 1600) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    backgroundColour
    isTextBlack: textColour
    link {
      ...Product
    }
    isFullWidth: layout
  }
`

// const ViewportDiv = ({ adjustBy = '10rem', className, children }) => {
//
//   const [height, setHeight] = useState('100vh')
//
//   const handleResize = () => {
//     setHeight(`${window.innerHeight}px`)
//   }
//
//   useEffect(() => {
//     handleResize()
//     window.addEventListener('resize', handleResize)
//     return () => {
//       window.addEventListener('resize', handleResize, false)
//     }
//   }, [])
//
//   return (
//     <div className={`${className}`} style={{
//       height: `calc(${height} - ${adjustBy})`
//     }}>
//       {children}
//     </div>
//   )
// }
