import React, { useRef, useState } from "react"
import useSessionStorage from "./useSessionStorage"
// ---------------------------------------------

export function useSplashState() {
	const [removeSplash, setRemoveSplash] = useSessionStorage('sedilia_splash', false)
	const [hideSplash, setHideSplash] = useState(removeSplash)
	const handleSplashTransitionEnd = () => setRemoveSplash(true)
	const handlePathAnimationEnd = () => setHideSplash(true)
	return {
		removeSplash,
		setRemoveSplash,
		hideSplash,
		setHideSplash,
		handleSplashTransitionEnd,
		handlePathAnimationEnd
	}
}

export const ShowAfterSplash = ({ className = "", splashFadeOut, children }) => (
  <div className={className} style={{
    opacity: splashFadeOut ? 1 : 0,
    transition: 'opacity 1.5s',
		transitionDelay: '1.5s',
  }}>
    {children}
  </div>
)

const Splash = ({
	hideSplash,
	handleSplashTransitionEnd,
	handlePathAnimationEnd
}) => {

  const el = useRef();
  const path = useRef();

  return (
    <div 
			ref={el} 
			className="sedilia inner align-center" 
			onTransitionEnd={handleSplashTransitionEnd} 
			onClick={handlePathAnimationEnd}
			style={{
      	opacity: !hideSplash ? 1 : 0
    	}}
		>
      <svg 
				className="s-for-sedilia" 
				version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" 
				x="0px" y="0px" viewBox="0 0 595.3 841.9" 
				style={{ enableBackground: "new 0 0 595.3 841.9" }} 
				xmlSpace="preserve"
			>
        <defs>
          <clipPath id="path-logo">
            <path d="M582.1,543.9c-39.5-100-168.3-127.5-214.2-140.5l-105.3-26.9c-51.5-12.7-104.4-28.4-142.6-56.5
            c-39.1-28.7-61.2-68.4-61.2-118.6c0,0,0-0.7,0-1c0-10.7,1.9-35.1,12.9-63.1c40.2-95,141.9-119.3,192.4-124.9
            c9.4-0.9,19.1-1.5,29.4-1.8c102.6,0,188.6,41.6,240,110.6c6.2,8.4,12.1,17.6,17.3,27.5l0.4-0.3l0,0l10.1-8.1
            C512.2,54.9,415.4,0,293.5,0C153.2,0,37.3,86.6,34.1,208.1c0,1.7-0.1,3.4-0.1,5c0,56.1,18.1,97,57.1,128.7
            c34.3,27.9,84.1,48.8,161.5,67.6l75.7,18.1c58.7,14.1,130.8,34.4,175.6,68c0.9,0.7,1.7,1.4,2.6,2.1c7.3,5.9,14,12.3,20,19
            c1.9,2.2,3.6,4.2,5.2,6.1c11.5,14.1,20.4,29.6,26.6,46.7c5.5,15.7,9.5,35.2,8.6,62.3c-1,33.6-25.6,175.5-236.8,195.8
            c-7.7,0.6-15.6,1.1-23.6,1.4c-9.9,0.2-19.2,0.1-27.7-0.3c-139-7.7-251.6-89.8-266.8-192.4L0,642.8c18.7,110.4,144,197.1,295.7,199.1
            c69.4,0.9,133.2-16.1,183.9-45.3l0,0c0,0,24.1-12.1,50.3-37c24.3-22.9,42.7-49.5,53.4-78.6c7.3-19.3,11.9-41.1,12.1-65.5
            C595.5,587.5,590.3,563.9,582.1,543.9z"/>
          </clipPath>
        </defs>
        <g id="fakes" clipPath="url(#path-logo)" >
          <path ref={path} onAnimationEnd={handlePathAnimationEnd} className="ink" strokeDasharray="2400" strokeDashoffset="" d="M555.9,144.6C540.6,118,504.2,68.9,442.3,38.2c-57-28.1-118.7-32.8-148.9-32.9c-51.1,0-105.5,13.4-149.9,40.2
          C86.3,80,46.4,136.1,46.4,209.9c1.2,20.6,1.5,75.8,58,119.9c43.7,34,118.3,55.5,153.1,63.3c0,0,61.6,15.1,78.4,19.4
          c4.8,1.2,13.5,3.3,23.3,5.8c39.8,10.4,116.7,31,162.6,71.1c32.5,28.2,58,70.5,59.6,125.4c1.6,37.8-10.8,78.2-39.2,116.5
          c-61.3,83.2-175.2,104.4-244.6,104.4C159.1,835.5,26,756.7,6.1,639.5" />
        </g>
      </svg>
    </div>
  )
}

export default Splash
