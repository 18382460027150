import React, { useState } from "react"
import { Link } from "gatsby"
import Img from 'gatsby-image'
import Swiper from 'react-id-swiper'
// ---------------------------------------------
import { ShowAfterSplash } from "../components/splash"
import Revealer from "../components/revealer"
// ---------------------------------------------
import '../css/components/swiper.scss'

const HeroGallery = ({ data, splashFadeOut }) => {

  const [swiper, updateSwiper] = useState(null)
  const [hasMoved, setHasMoved] = useState(false)

  const options = {
    allowTouchMove: false,
    simulateTouch: false,
    loop: false,
    pagination: false,
    slidesPerView: 2,
    breakpoints: {
      768: {
        slidesPerView: 1
      }
    }
  }

  const handleTouchEnd = () => {
    if (!hasMoved && swiper !== null) {
      swiper.slideNext()
      setHasMoved(true)
    }
  }

  return (
    <Revealer key="hero-gallery">
      <div className="hero-gallery" onTouchEnd={handleTouchEnd}>

        <Swiper {...options} getSwiper={updateSwiper}>
          <div className="swiper-slide">
            <Img className="fs cover" fluid={data.primaryImage.fluid} loading="eager" />
            <div className="pabs hero-fs-title flex-center">
              <center>
                <ShowAfterSplash splashFadeOut={splashFadeOut}>
                  <h2 className={!data.isTextBlack ? "color-white" : ""}>
                    {data.link.title}
                  </h2>
                </ShowAfterSplash>
              </center>
            </div>
          </div>
          <div className="swiper-slide">
            <div className="hero-second-image" style={{
              backgroundColor: data.backgroundColour || '#FFF'
            }}>
              <div className="fs">
                <Link to={`/${data.link.slug}`}>
                  <Img className="fit-contain" fluid={data.link.thumbnail.fluid} loading="eager" />
                </Link>
              </div>
              <div className="hero-gallery-caption">
                <ShowAfterSplash splashFadeOut={splashFadeOut}>
                  <Link to={`/${data.link.slug}`} className="block">
                    <p className="max32">
                      <strong className="show-on-mobile">{data.link.title}{" "}</strong>
                      <span>{data.link.shortDescription}</span>
                    </p>
                  </Link>
                </ShowAfterSplash>
              </div>
            </div>
          </div>
        </Swiper>

      </div>
    </Revealer>
  )
}

export default HeroGallery
